<template>
  <div class="home-unit">
    <p class="home-unit-title-key">{{ title }}</p>
    <p class="home-unit-title-value">{{ keyValue }}</p>
    <div class="home-unit-chart" :id="title"></div>
    <div class="home-unit-gray-line"></div>
    <div class="home-unit-info">
      <div class="home-unit-info-item">
        <p>{{ info1Title }}</p>
        <p class="home-unit-info-item-value">{{ info1Value }}</p>
      </div>
      <div v-if="info2Title != false" class="home-unit-info-item">
        <p>{{ info2Title }}</p>
        <p class="home-unit-info-item-value">{{ info2Value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { TinyColumn } from "@antv/g2plot";
import { TinyArea } from "@antv/g2plot";
import { Progress } from "@antv/g2plot";

export default {
  props: {
    title: String,
    keyValue: String,
    info1Title: String,
    info1Value: String,
    info2Title: {
      type: String,
      default: "",
    },
    info2Value: String,
    valueStr: String,
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {
    processedValue() {
      switch (this.title) {
        case "设备使用率":
          return parseFloat(this.valueStr) / 100;
        default:
          const countStringArray = this.valueStr.split(",");
          let countNumberArray = [];
          for (const item of countStringArray) {
            let countNumber = parseInt(item) + 1; //加1是为了好看（0也有图）
            countNumberArray.push(countNumber);
          }
          return countNumberArray;
      }
    },

    yMax() {
      switch (this.title) {
        case "设备使用率":
          return 0;
        default:
          let maxValue = Math.max(...this.processedValue);
          return maxValue > 15 ? maxValue : 15; //当数据为1时maxValue为15视觉效果最好
      }
    },
  },

  mounted() {
    if (this.chart == null) {
      this.renderChart();
    } else {
      this.chart.changeData(this.processedValue);
    }
  },
 
  methods: {
    renderChart() {
      switch (this.title) {
        case "报告总数":
          this.chart = new TinyColumn(this.title, {
            autoFit: true,
            data: this.processedValue,
            color: "#33ABFB",
            yAxis: {
              max: this.yMax,
            },
            tooltip: {
              customContent: function (x, data) {
                return `${data[0]?.data?.y - 1}`; //减1是为了显示数据的正确
              },
            },
          });
          this.chart.render();
          break;
        case "用户总数":
          this.chart = new TinyArea(this.title, {
            yAxis: {
              max: this.yMax,
            },
            autoFit: true,
            data: this.processedValue,
            smooth: true,
            areaStyle: {
              fill: "#8543E0",
              stroke: "#8543E0",
              strokeOpacity: 1,
              fillOpacity: 1,
            },
            tooltip: {
              customContent: function (x, data) {
                return `${data[0]?.data?.y - 1}`; //减1是为了显示数据的正确
              },
            },
          });
          this.chart.render();
          break;
        case "借出设备":
          this.chart = new TinyColumn(this.title, {
            yAxis: {
              max: this.yMax,
            },
            autoFit: true,
            data: this.processedValue,
            color: "#FFA945",
            tooltip: {
              customContent: function (x, data) {
                return `${data[0]?.data?.y - 1}`; //减1是为了显示数据的正确
              },
            },
          });
          this.chart.render();
          break;
        case "设备使用率":
          this.chart = new Progress(this.title, {
            autoFit: true,
            percent: this.processedValue,
            color: ["#13C2C2", "#F0F2F5"],
          });
          this.chart.render();
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>


.home-unit {
  background-color: white;
  border-radius: rem(2);
  width: 24%;
  height: 100%;
  box-sizing: border-box;
  padding: rem(20);

  &-title-key {
    color: rgba($color: black, $alpha: 0.45);
    font-size: rem(14);
    height: rem(19);
  }

  &-title-value {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(30);
    height: rem(35);
    margin-top: rem(6);
  }

  &-chart {
    width: 100%;
    height: rem(50);
    margin-top: rem(0);
  }

  &-gray-line {
    background-color: #e8e8e8;
    width: 100%;
    height: rem(1);
    margin-top: rem(8);
  }

  &-info {
    display: flex;
    justify-content: space-between;
    height: rem(19);
    margin-top: rem(7);

    &-item {
      color: rgba($color: black, $alpha: 0.65);
      font-size: rem(14);
      height: 100%;
      display: flex;
      align-items: center;

      &-value {
        margin-left: rem(30);
      }
    }
  }
}
</style>