<template>
  <button class="home-button" @click="onClick">
    <img :src="icon" />
    <p>{{ title }}</p>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },

  methods: {
    onClick() {
      this.$emit("onButtonClick", this.title);
    },
  },
};
</script>

<style lang="scss" scoped>

.home-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: rem(52);
    height: rem(52);
  }

  p {
    color: #909399;
    font-size: rem(14);
    height: rem(19);
    margin-top: rem(6);
  }
}
</style>