<template>
  <div class="home">
    <div class="home-top">
      <img class="home-top-avatar" src="@imgs/avatar-big.png" />
      <div class="home-top-middle">
        <p class="home-top-middle-name">{{ nameText }}</p>
        <p class="home-top-middle-hospital">{{ hospitalText }}</p>
      </div>
      <div class="home-top-right">
        <home-button class="home-top-right-button" :icon="addIcon" title="新增用户"
          @onButtonClick="onButtonClick"></home-button>
        <home-button class="home-top-right-button" :icon="recycleIcon" title="设备借还"
          @onButtonClick="onButtonClick"></home-button>
        <home-button class="home-top-right-button" :icon="messageIcon" title="消息"
          @onButtonClick="onButtonClick"></home-button>
      </div>
    </div>
    <div v-if="caculateModel" class="home-middle">
      <unit :title="'报告总数'" :keyValue="totalReportNum" :info1Title="'今日报告'" :info1Value="todayReportNum"
        :valueStr="reportDay" ref="reportUnit" />
      <unit :title="'用户总数'" :keyValue="totalUserNum" :info1Title="'今日新增'" :info1Value="todayUserNum" :valueStr="userDay"
        ref="userUnit" />
      <unit :title="'借出设备'" :keyValue="totalBorrowCount" :info1Title="'今日借出'" :info1Value="todayBorrowCount"
        :valueStr="borrowDay" ref="deviceUnit" />
      <unit :title="'设备使用率'" :keyValue="deviceUseRate" :info1Title="'全部'" :info1Value="totalDeviceNum" :info2Title="'可用'"
        :info2Value="availableDeviceNum" :valueStr="`${caculateModel.useRate}`" ref="rateUnit" />
    </div>
    <div class="home-bottom">
      <div class="home-bottom-top">
        <p class="home-bottom-top-title">动态</p>
      </div>
      <ul class="home-bottom-list" ref="ul" @scroll="onScroll">
        <cell v-for="(item, index) of momentArray" :key="index" :model="item"></cell>
      </ul>
      <p v-if="momentArray == false" class="home-bottom-empty-info">没有动态</p>
    </div>
  </div>
</template>

<script>
import HomeButton from "@c/home/home-button.vue";
import Cell from "@c/home/home-cell.vue";
import Unit from "@c/home/home-unit.vue";
import { CacheTool } from "@js/cache-tool.js";

export default {
  components: {
    HomeButton,
    Cell,
    Unit,
  },

  data() {
    return {
      //buttonIcon
      addIcon: require("@imgs/add-purple.png"),
      recycleIcon: require("@imgs/recycle-blue.png"),
      messageIcon: require("@imgs/message.png"),
      //caculateModel
      caculateModel: undefined,
      //momentArray
      momentArray: [],
      pageSize: 6, // 每页显示条数
      currentPage: 1, // 当前的页数
      isRequestLocked: false, //避免上拉多次触发请求
      isDataLoadCompleted: false,

    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },

    nameText() {
      return `${placeholderText(this.$store.state.accountInfo.trueName)}，欢迎您！`
    },

    hospitalText() {
      return `${placeholderText(this.$store.state.accountInfo.roleName)} ｜ ${placeholderText(this.$store.state.accountInfo.hospitalName)}`
    },

    totalReportNum() {
      return `${placeholderText(this.caculateModel.totalReportNum.toFixed())}`
    },

    todayReportNum() {
      return `${placeholderText(this.caculateModel.todayReportNum.toFixed(), ' 份')} `
    },

    reportDay() {
      const reportDayArray = this.caculateModel.reportDay.map((item) => {
        return `${item.num}`
      })
      return reportDayArray.join(',')
    },

    totalUserNum() {
      return `${placeholderText(this.caculateModel.totalUserNum.toFixed())}`
    },

    todayUserNum() {
      return `${placeholderText(this.caculateModel.todayUserNum.toFixed(), ' 人')}`
    },

    userDay() {
      const userDayArray = this.caculateModel.userDay.map((item) => {
        return `${item.num}`
      })
      return userDayArray.join(',')
    },

    totalBorrowCount() {
      return `${placeholderText(this.caculateModel.borrowCount.toFixed())}`
    },

    todayBorrowCount() {
      return `${placeholderText(this.caculateModel.todayBorrowCount.toFixed(), ' 次')}`
    },

    borrowDay() {
      const borrowDayArray = this.caculateModel.borrowDay.map((item) => {
        return `${item.num}`
      })
      return borrowDayArray.join(',')
    },

    deviceUseRate() {
      return `${placeholderText(this.caculateModel.useRate.toFixed(), '%')}`
    },

    totalDeviceNum() {
      return `${placeholderText(this.caculateModel.totalDeviceNum.toFixed(), ' 台')}`
    },

    availableDeviceNum() {
      return `${placeholderText(this.caculateModel.availableDeviceNum.toFixed(), ' 台')}`
    }

  },

  mounted() {
    document.addEventListener("onScroll", this.onScroll);
    this.caculateRequest();

  },

  watch: {
    hospitalID: function () {
      if (CacheTool.getLoginInfo()) {
        this.currentPage = 1;
        this.caculateRequest();
        this.momentListRequest(true);
      }
    },
  },

  methods: {
    placeholderText(text, unit) {
      return placeholderText(text, unit)
    },

    onButtonClick(title) {
      switch (title) {
        case "新增用户":
          this.$emit("onHomeAddUserClick");
          break;
        case "设备借还":
          this.$emit("onHomeRecycleDeviceClick");
          break;
        case "消息":
          this.$toast.showRed(MSG.functionNotOpen);
          break;
      }
    },

    onScroll() {
      if (
        this.$refs.ul.scrollTop + 1 >=
        this.$refs.ul.scrollHeight - this.$refs.ul.clientHeight
      ) {
        if (!this.isDataLoadCompleted) {
          this.momentListRequest(false);
        }
      }
    },

    // request
    async caculateRequest() {
      try {
        const params = {
          id: this.hospitalID,
        }
        const response = await this.$api.getCaculate(params)
        this.caculateModel = response;
        this.momentListRequest(true);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async momentListRequest(isFromStart) {
      if (this.isRequestLocked) {
        return;
      }
      this.isRequestLocked = true;
      if (isFromStart) {
        this.momentArray = [];
      }
      try {
        const params = {
          "project": '八戒睡眠',
          "hospitalIds": this.hospitalID == null ? null : [this.hospitalID],
          // "userAgent" : 'web',
          "pageNum": this.currentPage,
          "pageSize": this.pageSize,
          "category": '睡眠机设备'
        };

        const data = await this.$api.logList(params);
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;

        this.isRequestLocked = false;
        this.momentArray = this.momentArray.concat(data.list); //累加
        this.isDataLoadCompleted = data.boolLastPage;
        if (!this.isDataLoadCompleted) {
          this.currentPage += 1;
        }
      } catch (error) {
        this.isRequestLocked = false;
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.home {
  background-color: #f0f2f5;
  width: 100%;
  flex-direction: column;

  &-top {
    background-color: white;
    height: rem(125);
    display: flex;
    box-sizing: border-box;
    padding-left: rem(24);

    &-avatar {
      width: rem(72);
      height: rem(72);
      margin-top: rem(27);
    }

    &-middle {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: rem(24);

      &-name {
        color: black;
        font-size: rem(20);
        height: rem(28);
        margin-top: rem(33);
      }

      &-hospital {
        color: rgba($color: black, $alpha: 0.45);
        font-size: rem(14);
        height: rem(20);
        margin-top: rem(11);
      }
    }

    &-right {
      align-self: flex-start;
      display: flex;
      margin-top: rem(23);
      margin-left: rem(58);

      &-button {
        margin-right: rem(49);
      }
    }
  }

  &-middle {
    width: 100%;
    height: rem(188);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(20);

    &-device-can-use {
      display: flex;

      &-key {
        color: rgba($color: black, $alpha: 0.65);
        font-size: rem(14);
        height: rem(19);
      }

      &-value {
        color: rgba($color: black, $alpha: 0.65);
        font-size: rem(14);
        height: rem(19);
        margin-left: rem(30);
      }
    }
  }

  &-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(20);

    &-top {
      background-color: white;
      border-top-left-radius: rem(2);
      border-top-right-radius: rem(2);
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      width: 100%;
      height: rem(55);

      &-title {
        color: rgba($color: black, $alpha: 0.85);
        font-size: rem(16);
        margin-left: rem(28);
      }
    }

    &-list {
      width: 100%;
      max-height: rem(348);
      overflow-y: scroll;
    }

    &-empty-info {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(14);
      width: 100%;
      margin-top: rem(100);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-pagination {
      width: 100%;
      height: rem(90);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>