<template>
  <div class="home-cell">
    <div class="home-cell-content">
      <img :src="icon" />
      <div class="home-cell-content-right">
        <p class="home-cell-content-right-description">{{ description }}</p>
        <p class="home-cell-content-right-time">{{ time }}</p>
      </div>
    </div>
    <div class="home-cell-bottomline"></div>
  </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
export default {
  props: {
    model: Object,
  },

  computed: {
    icon() {
      return this.model.image;
    },
    description() {
      return this.model.logContent;
    },
    time() {
      return this.model.createTime;
    },
  },
};
</script>

<style lang="scss" scoped>


.home-cell {
  background-color: white;
  width: 100%;
  height: rem(87);
  box-sizing: border-box;
  padding-left: rem(28);
  padding-right: rem(56);

  &-content {
    width: 100%;
    height: rem(86);
    // flex:1;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;

    img {
      width: rem(36);
      height: rem(36);
      border-radius: 50%;
    }

    &-right {
      height: 100%;
      margin-left: rem(19);

      &-description {
        color: rgba($color: black, $alpha: 0.65);
        font-size: rem(14);
        height: rem(20);
        margin-top: rem(24);
      }

      &-time {
        color: rgba($color: black, $alpha: 0.45);
        font-size: rem(14);
        height: rem(20);
        margin-top: rem(6);
      }
    }
  }

  &-bottomline {
    background-color: #e8e8e8;
    width: 100%;
    height: rem(1);
  }
}
</style>